#top-left,
#bottom-right {
    animation-name: pulse;
    -webkit-animation-name: pulse;

    animation-duration: 1s;
    -webkit-animation-duration: 1s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

#top-right,
#bottom-left {
    animation-delay: .5s;
    animation-name: pulse;
    -webkit-animation-name: pulse;

    animation-duration: 1s;
    -webkit-animation-duration: 1s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}


@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}